<template>
  <div style="padding: 1rem;">
    <b-card>
      <b-row class="mb-1 pl-1 pr-0">
        <b-col>
          <!-- <h4>{{$t('Register creation')}}</h4>
          <h6>{{$t('Maintenance to Property', {house: myContracts.contractSelected.housingname})}}</h6> -->
          <h4>{{$t('Create')}} {{$t('maintenance requests')}}</h4>
        </b-col>
        <b-col>
          <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'my-property', params: {index:1, info: this.$t('maintenance requests')} }">
            <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
          </b-button>
        </b-col>
      </b-row>

      <RequestForm
        :isLoadingControlAccessData="isLoadingControlAccessData"
        :isSavingControlAccessData="isSavingControlAccessData"
        :requestData="initialData"
        :originalAccessData="initialData"
        @save-register="saveRecord"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import RequestForm from '@/modules/myProperty/components/MaintenanceRequest/RequestForm'
import { showAlertMessage } from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"

export default {
  mixins:[ utils ],
  components: {
    RequestForm,
  },
  data() {
    return {
      isLoadingControlAccessData: false,
      isSavingControlAccessData: false,
      initialData: {
        reason: null,
        description: null,
        startDate: null,
        endDate: null,
				notes: null,

        provider: null,
        providerName: null,
        providerPhone: null,
        providerEmail: null,

        dateOut: null,
        timeIn: null,
        timeOut: null,

        files:[],
        images:[],
      }
    }
  },
  computed:{
    ...mapState('auth', ['user', 'myContracts']),
  },
  methods: {
    ...mapActions('myProperty', ['saveMaintenanceRequest']),
    async saveRecord(data){
      // validar si es admin desde mixin en utils
      if( this.isAdmin ){
        return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
      }
      this.isSavingControlAccessData = true
      data.idOwner = this.user.idOwner
      data.idContract = this.myContracts.contractSelected.id
      data.status = 1

		  await this.pushData('onClick', 'attemptToCreateaMaintenanceRequest', 'attemptToCreateaMaintenanceRequest', this.$route.name)

      const response = await this.saveMaintenanceRequest(data)
      this.isSavingControlAccessData = false

      if (response) {
        showAlertMessage( this.$t('Access saved'), 'InfoIcon', `🎉🎉 ${this.$t('Register saved')}`, 'success', 4000, 'bottom-right')
        await this.pushData('onSubmit', 'maintenanceRequestCreationRecord', 'maintenanceRequestCreationRecord', this.$route.name)
        this.$router.push({name: 'my-property', params: {index:1, info: this.$t('maintenance requests')}})
        // await this.pushData('onSubmit', 'accessControlLogWasCreated', 'accessControlLogWasCreated', this.$route.name) // push a back del registro
      }
      if( !response ){
        showAlertMessage( this.$t('something failed'), 'InfoIcon', this.$t(`try again`), 'danger', 4000, 'bottom-right')
      }
    }
  }
}
</script>
